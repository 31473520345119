import { graphql, useStaticQuery } from 'gatsby'

export default function useBlogMetadata() {
  return useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            author
            description
            siteUrl
            languages
          }
        }
      }
    `
  )
}
