/* eslint-disable-next-line */
import { Link as GatsbyLink } from 'gatsby'
import React from 'react'

import { useLangContext } from '~/components/LangContext'

const Link = ({ toIncludesLang = false, to, ...props }) => {
  const lang = useLangContext()
  const internalLink = to.startsWith('/')
  return <GatsbyLink to={internalLink && !toIncludesLang ? `/${lang}${to}` : to} {...props} />
}

export default Link
