import styled from 'styled-components'

import styles from '~/utils/styles'

const Markdown = styled.div`
  display: block;
  font-size: 1.8rem;
  line-height: 1.8;
  font-weight: 400;
  letter-spacing: 0.04rem;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 2.5rem 0;
  }

  h1 {
    font-size: 3.2rem;
    margin-bottom: 2rem;

    ${styles.breakpoints.md} {
      font-size: 4rem;
      margin-bottom: 3rem;
    }
  }

  h2 {
    font-size: 2.4rem;

    ${styles.breakpoints.md} {
      font-size: 3.2rem;
    }
  }

  h3 {
    font-size: 2rem;

    ${styles.breakpoints.md} {
      font-size: 2.4rem;
    }
  }

  h4,
  h5,
  h6 {
    font-size: 1.6rem;
    opacity: 0.8;
  }

  p {
    margin-bottom: 1rem;
    white-space: break-spaces;
  }

  small {
    font-size: 1rem;
  }

  b,
  strong {
    font-weight: bold;
  }

  blockquote {
    padding: ${({ theme: { spacing } }) => `${spacing.xs} 0 ${spacing.xs} ${spacing.md}`};
    margin: ${({ theme: { spacing } }) => `${spacing.md} 0`};
    opacity: 0.8;
    color: inherit;
    font-size: 1.8rem;
    font-style: italic;
    border-left: 0.3rem solid hsla(0, 0%, 0%, 0.9);
    border-left-color: ${({ theme: { colors } }) => colors.pink};

    p:last-child {
      margin-bottom: 0;
    }
  }

  a {
    color: ${({ theme: { colors } }) => colors.blue};
    text-decoration: underline;

    &:focus,
    &:hover {
      color: ${({ theme: { colors } }) => colors.darkBlue};
    }
  }

  em {
    font-style: italic;
  }

  del {
    text-decoration: line-through;
  }

  ul,
  ol {
    padding-left: 1em;
    margin: 20px 10px;
  }

  ul {
    list-style: disc;
  }

  ol {
    list-style: decimal;
  }

  .gatsby-resp-image-image {
    box-shadow: rgba(128, 128, 128, 0.4) 0px 0px 20px 0 !important;
    border-radius: 8px;
  }
`

export default Markdown
