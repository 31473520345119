import React from 'react'
import { createGlobalStyle, ThemeProvider } from 'styled-components'
import reset from 'styled-reset'

import Footer from '~/components/Footer'
import { DEFAULT_LANG, LangContextProvider } from '~/components/LangContext'
import NavBar from '~/components/NavBar'
import theme from '~/utils/theme'

const GlobalStyle = createGlobalStyle`
  ${reset}

  a,
  *::before,
  *::after {
    box-sizing: inherit;
  }

  *,
  a:visited,
  a:active {
    text-decoration: none;
  }

  *,
  *:focus {
    outline: none;
  }

  html {
    box-sizing: border-box;
    background-color: ${({ theme: { colors } }) => colors.white};
  }

  *,
  *::after,
  *::before {
    box-sizing: border-box;
  }

  html,
  body {
    -webkit-font-smoothing: antialiased;
    -webkit-tap-highlight-color: transparent;
    font-family: 'Merriweather', sans-serif;
    font-weight: normal;
    font-size: 10px;
    color: ${({ theme: { colors } }) => colors.black};

    padding: 0;
    font-size: 10px;
    position: relative;
    overflow-x: hidden;
  }

  body {
    margin: 0 auto;
    min-height: 100%;
    background-color: white;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: 'Montserrat', sans-serif;
    line-height: 1;
    font-weight: 900;
  }

  a {
    color: ${({ theme: { colors } }) => colors.blue};
  }
`

const Layout = ({ children, includeNavBar = true, lang = DEFAULT_LANG }) => {
  return (
    <LangContextProvider lang={lang}>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <main>
          {includeNavBar && <NavBar />}
          {children}
        </main>
        <Footer />
      </ThemeProvider>
    </LangContextProvider>
  )
}

export default Layout
