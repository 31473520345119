import i18nData from '@/content/data/i18n.json'
import { DEFAULT_LANG, useLangContext } from '~/components/LangContext'

export const AUTHOR_POSTS_NO_RESULTS = 'AUTHOR_POSTS_NO_RESULTS'
export const AUTHOR_POSTS_WELCOME = 'AUTHOR_POSTS_WELCOME'
export const AUTHOR_TRANSLATED_OPTIONS = 'AUTHOR_TRANSLATED_OPTIONS'
export const BY_PREFIX = 'BY_PREFIX'
export const HOME = 'HOME'
export const HOME_DESCRIPTION = 'HOME_DESCRIPTION'
export const HOME_TRANSLATED_OPTIONS = 'HOME_TRANSLATED_OPTIONS'
export const NO_POSTS = 'NO_POSTS'
export const POST_TRANSLATED_OPTIONS = 'POST_TRANSLATED_OPTIONS'
export const SHARE = 'SHARE'
export const READING_TIME = 'READING_TIME'

const DICTIONARY = i18nData.entries.reduce((acc, { key, ...value }) => {
  acc[key] = value
  return acc
}, {})

const getI18n = (key, lang) => DICTIONARY[key]?.[lang] ?? DICTIONARY[key]?.[DEFAULT_LANG] ?? key

export const useI18nArray = (keys) => {
  const lang = useLangContext()
  return keys.map((key) => getI18n(key, lang))
}

/**
 * Replaces each pattern wrapped with [[]] with respective value.
 *
 * @example
 * applyPlaceholders('[[FOO]] is cool', { FOO: 'Django Reinhardt' })
 * => 'Django Reinhardt is cool'
 *
 * @param {string} template String with the patterns to replace.
 * @param {object} data Where each key corresponds to each pattern.
 * @returns {string} string with the placeholders applied.
 */
const applyPlaceholders = (template, data) =>
  Object.entries(data).reduce((prev, [k, v]) => prev.replace(`[[${k}]]`, v), template)

/**
 * Apply the corresponding dictionary. An additional argument can be passed
 * to apply placeholders wrapped with [[]].
 *
 * @example
 * // Considering that AUTHOR_TITLE is "[[AUTHOR]] is cool"
 * useI18n('AUTHOR_TITLE', { AUTHOR: 'Django Reinhardt' })
 * => 'Django Reinhardt is cool'
 *
 * @param {string} key Dictionary key.
 * @param {object} [placeholders] Object used to replace values. Each key corresponds
 * to a pattern. Example: '[[FOO]]' corresponds to the key FOO in the object.
 *
 * @returns {string} Translated text.
 */
const useI18n = (key, placeholders) => {
  const lang = useLangContext()
  const translation = getI18n(key, lang)

  return placeholders ? applyPlaceholders(translation, placeholders) : translation
}

export default useI18n
