import styled from 'styled-components'

import styles from '~/utils/styles'

const Container = styled.div`
  margin: 0 auto;
  ${({ theme: { spacing } }) => `
    padding-left: ${spacing.sm};
    padding-right: ${spacing.sm};
  `}

  ${styles.breakpoints.md} {
    max-width: 80%;
    padding-left: 0;
    padding-right: 0;
  }

  ${styles.breakpoints.lg} {
    max-width: 70%;
  }

  ${styles.breakpoints.xl} {
    max-width: 80rem;
  }
`

export default Container
