import React, { createContext, useContext } from 'react'

// FIXME: replace with defaultLang from siteMetadata
export const DEFAULT_LANG = 'en'

const LangContext = createContext(DEFAULT_LANG)

export const useLangContext = () => useContext(LangContext)

export const LangContextProvider = ({ children, lang }) => {
  return <LangContext.Provider value={lang}>{children}</LangContext.Provider>
}
