import styles from './styles'

const BLUE = '#007CE0'
const PINK = '#E45372'
const GRAY = '#403F3F'

export default {
  colors: {
    black: '#1A1A1A',
    blue: BLUE,
    darkBlue: styles.mixColor(BLUE, -0.2),
    darkPink: styles.mixColor(PINK, -0.2),
    gray: GRAY,
    lightGray: '#DFDFDF',
    orange: '#D66E00',
    pink: PINK,
    white: '#FFFFFF',
  },
  breakpoints: ['48rem', '64rem', '80rem'],
  fonts: {
    heading: 'Montserrat',
    body: 'Merriweather',
    code: 'Source Code Pro',
  },
  spacing: {
    xs: '0.5rem',
    sm: '2rem',
    md: '2rem',
    lg: '4rem',
    xl: '8rem',
  },
}
