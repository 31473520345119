import React from 'react'
import styled from 'styled-components'

import Container from '~/components/Container'
import Link from '~/components/Link'
import useBlogMetadata from '~/staticQueries/useBlogMetadata'
import styles from '~/utils/styles'
import useI18n, { BY_PREFIX } from '~/utils/useI18N'

export default () => {
  const {
    site: {
      siteMetadata: { author },
    },
  } = useBlogMetadata()

  return (
    <Container>
      <Footer>
        <Author>
          <span>{useI18n(BY_PREFIX)}</span>
          <Link to="/">{author}</Link>
        </Author>
      </Footer>
    </Container>
  )
}

const Footer = styled.footer`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: ${({ theme: { spacing } }) => `${spacing.md} 0`};

  ${styles.breakpoints.md} {
    justify-content: flex-end;
  }
`
const Author = styled.p`
  font-size: 1.2rem;
  font-weight: bold;
  font-family: 'Source Code Pro', monospace;

  a {
    text-decoration: none;
    color: ${({ theme: { colors } }) => colors.pink};

    &:focus,
    &:hover {
      color: ${({ theme: { colors } }) => colors.darkPink};
    }
  }
`
