import React from 'react'
import styled from 'styled-components'

import Container from '~/components/Container'
import Link from '~/components/Link'
import Logo from '~/images/logo.inline.svg'
import useBlogMetadata from '~/staticQueries/useBlogMetadata'
import useI18n, { HOME } from '~/utils/useI18N'

const NavBar = () => {
  const {
    site: {
      siteMetadata: { title },
    },
  } = useBlogMetadata()

  return (
    <StyledNavBar>
      <Container>
        <HomeLink to="/" title={useI18n(HOME)}>
          <HomeIcon>
            <Logo width={24} height={24} />
          </HomeIcon>
          <h1>{title}</h1>
        </HomeLink>
      </Container>
    </StyledNavBar>
  )
}

export default NavBar

const HomeIcon = styled.div`
  margin-right: 1rem;
`

const StyledNavBar = styled.div`
  background: white;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.1);
  ${({ theme: { spacing } }) => `
    padding-bottom: ${spacing.sm};
    padding-top: ${spacing.sm};
  `}
`

const HomeLink = styled(Link)`
  align-items: center;
  display: flex;
  color: ${({ theme: { colors } }) => colors.pink};
  text-decoration: none;

  svg {
    transition: 350ms transform;
  }

  &:focus,
  &:hover {
    svg {
      transform: scale(1.1);
    }
  }
`
